import * as React from "react"
import Layout from "../components/Layout"
import "../styles/global.css"
import Link from "gatsby-link"
import { Container, Row, Col } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../components/Hero"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - IMSSDARM Asian Division</title>
        <link rel="canonical" href="https://imssdarm.asia/" />
        <meta name="description" content="The third angel’s message, embracing the messages of the first and second angels, is the message for this time."></meta>
      </Helmet>
      <Hero />

      {/* OUR MESSAGE SECTION */}
      <div className="our-message">
        <Container>
          <Row>
            <Col md="8" className="m-auto text-center">
              <h2>Our Message</h2>
              <p>
                The third angel’s message, embracing the messages of the first
                and second angels, is the message for this time. We are to raise
                aloft the banner on which is inscribed: “The commandments of
                God, and the faith of Jesus.” The world is soon to meet the
                great Lawgiver over His broken law. This is not the time to put
                out of sight the great issues before us. God calls upon His
                people to magnify the law and make it honorable.
              </p>
              <Link
                className="btn btn-primary btn-md text-uppercase mt-4"
                to="/who-we-are"
              >
                Read all details
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      {/* OUR DEPARTMENTS SECTION */}
      <div className="our-departments">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2 className="text-white">Departments</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-evangelism.jpeg"
                  alt="Evangelism"
                />
                <div className="info">
                  <h4>Evangelism</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-ministerial.jpeg"
                  alt="Ministerial"
                />
                <div className="info">
                  <h4>Ministerial</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-canvassing.jpeg"
                  alt="Canvassing"
                />
                <div className="info">
                  <h4>Canvassing</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-youth.jpeg"
                  alt="Youth"
                />
                <div className="info">
                  <h4>Youth</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-family.jpeg"
                  alt="Family"
                />
                <div className="info">
                  <h4>Family</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-education.jpeg"
                  alt="Education"
                />
                <div className="info">
                  <h4>Education</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-multimedia.jpeg"
                  alt="Multimedia"
                />
                <div className="info">
                  <h4>Multimedia</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-publishing.jpeg"
                  alt="Publishing"
                />
                <div className="info">
                  <h4>Publishing</h4>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="card shadowed">
                <StaticImage
                  src="../images/background-health.jpeg"
                  alt="Health"
                />
                <div className="info">
                  <h4>Health</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* OUR VISION SECTION */}

      <div className="our-vision">
        <Container>
          <Row className="d-flex align-items-center">
            <Col md={6}>
              <StaticImage
                src="../images/vision-background.jpeg"
                alt="Our vision"
              />
            </Col>
            <Col md={6}>
              <h2>Our Vision</h2>
              <p>
                To increase in the number of membership by extending the work in
                virgin territories and various countries and in different levels
                of the organization in Asia.
              </p>
              <p>
                Revelation 18: 1 And after these things I saw another angel come
                down from heaven, having great power; and the earth was
                lightened with his glory.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
