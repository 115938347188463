import React from 'react';
import Swiper from 'react-id-swiper'
import 'swiper/swiper.min.css'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import { Row, Col } from 'reactstrap'

const Hero = () => {
  const params = {
    slidesPerView: 'auto',
		spaceBetween: 0,
		speed: 1000,
		autoplay: {
			delay: 7000,
			disableOnInteraction: false,
		},
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }
  return(
    <Swiper {...params}>
      <div>
        <StaticImage
          src="../images/slider-3.png"
          alt="Slider 3"
          backgroundColor="#613d47"
          layout="fullWidth"
          style={{
          gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          maxHeight: 768
          }}
        />
        <Row>
          <Col md={6} className="m-auto">
            <div className="info">
              <h3 className="text-white">Evangelism</h3>
              <h2 className="text-white">The church is God’s appointed agency for the salvation of men</h2>
              <Link to="/our-churches" className="btn btn-primary text-uppercase">Visit our Church</Link>
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <StaticImage
          src="../images/slider-2.jpg"
          alt="Slider 3"
          backgroundColor="#613d47"
          layout="fullWidth"
          style={{
          gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          maxHeight: 768
          }}
        />
        <Row>
          <Col md={6} className="m-auto">
            <div className="info">
              <h3 className="text-white">Musicians</h3>
              <h2 className="text-white">My lips will shout for joy when I sing praise to you— I whom you have delivered.</h2>
              <Link to="/our-churches" className="btn btn-primary text-uppercase">Visit our Church</Link>
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <StaticImage
          src="../images/slider-1.jpeg"
          alt="Slider 3"
          backgroundColor="#613d47"
          layout="fullWidth"
          style={{
          gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          maxHeight: 768
          }}
        />
        <Row>
          <Col md={6} className="m-auto">
            <div className="info">
              <h3 className="text-white">Musicians</h3>
              <h2 className="text-white">My lips will shout for joy when I sing praise to you— I whom you have delivered.</h2>
              <Link to="/our-churches" className="btn btn-primary text-uppercase">Visit our Church</Link>
            </div>
          </Col>
        </Row>
      </div>

    </Swiper>
  )
}
export default Hero;
